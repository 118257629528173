.App {
  display: flex;
  width: 100%;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 5px 0px;
}

.logo {
  height: 50px;
}

.paneAction {
  background-color: #ededed;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.paneActionTop {
  flex: 1;
  background: #222222;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "logo settings"
    "venueName timeDisplay"
    "orders orders";
  column-gap: 10px;
  row-gap: 15px;
}

.logo {
  grid-area: logo;
}

.venueName {
  grid-area: venueName;
}

.settingsIcon {
  grid-area: settings;
}

.timeDisplay {
  grid-area: timeDisplay;
}

.refreshOrders {
  grid-area: orders;
}

.paneActionBottom {
  flex: 5;
  overflow-y: scroll;
  text-align: center;
}

.paneDetail {
  flex: 3;

  overflow-y: scroll;
}

.orderList {
  font-size: 22px;
  font-weight: 600;
  padding: 20px 20px 150px 20px;
}

.orderList > div {
  margin-bottom: 10px;
}

.loginForm {
  height: 330px;
  margin: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginForm input {
  margin-bottom: 20px;
  font-size: 30px;
}

.orderListRow {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  margin: 4px 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.orderListRow:nth-child(2n) {
  background-color: #ddd;
}

.backButton {
  margin: 15px 0px;
}
.settingsIcon {
  font-size: 30px;

  padding: 10px 5px 10px 2px;
}
.icon-spin {
  width: 100%;

  position: relative;
  margin-top: 200px;
  font-size: 90px;
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
}

.status {
  font-size: 14px;
}

.orderListRow.blink {
  display: flex;
  justify-content: space-between;
  padding: 40px 30px;
  font-size: 35px;
  border: 5px solid black;
  margin: 2px 10px;
  background-color: red;
  border-radius: 5px;
  animation: blinker infinite cubic-bezier(0.5, 0, 1, 1) 0.7s;
}

.orderDetail {
  padding: 20px 20px 150px 20px;
}

.orderDetail > div {
  margin-bottom: 20px;
}

.orderDetailSide {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.orderDetailSide h3 {
  text-transform: uppercase;
  font-size: 22px;
  box-sizing: border-box;
}

.futureOrderHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.futureOrderHeader {
  font-weight: 900;
  font-size: 18px;
  margin: 20px 0px 10px 0px;
}

.futureOrder {
  width: 85%;
  background-color: darkred;
  padding: 5px;
  height: 65px;
  color: white;
  border-radius: 5px;
  font-weight: 900;
  font-size: 20px;
}

.timesContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  justify-content: space-between;
}

.timesContainer button {
  width: 45%;
  height: 40px;
  margin-bottom: 10px;
}

.timesContainer .fullButton {
  width: 100%;
}

.settingsHolder {
  display: flex;
  flex-direction: column;
}

button {
  min-height: 40px;
  background-color: #787878;
  color: white;
  font-size: 20px;
  border-radius: 10px;
  margin: 4px;
}

.notes {
  border: 2px solid black;
  padding: 10px;
  font-size: 24px;
  border-radius: 5px;
}

.notes b {
  text-decoration: underline;
}

@media only screen and (max-width: 700px) {
  .App {
    display: flex;
    flex-direction: column;
  }

  .paneActionTop {
    grid-template-columns: 120px auto auto 120px;
    grid-template-rows: auto auto;
    grid-template-areas:
      "logo venueName timeDisplay settings"
      ". orders orders .";
    column-gap: 10px;
    row-gap: 15px;
    align-items: center;
    padding: 10px;
  }

  .futureOrderHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  .futureOrderHolder button {
    width: 30%;
  }

  .orderDetailSide {
    text-align: center;
    display: flex;
    flex-direction: row;
  }

  .orderDetailInfo {
    padding: 20px 0px;
    width: 40%;
  }

  .orderDetailButtons {
    width: 60%;
  }

  .refreshOrders button {
    width: 100%;
  }

  .paneAction {
    display: block;
    flex: none;
  }

  .paneDetail {
    flex: 5;
  }
}
